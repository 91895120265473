// Palette
const primaryColor = '#ec524b';
const whiteColor = '#fff';
const blackColor = '#000';

// Themes
const theme = {
    palette: {
        primary: primaryColor,
        blue: '#0087b1',
        blueGray: '#333',
        gray: '#666',
        white: whiteColor,
        black: blackColor,
    },
    color: {
        primary: blackColor,
        secondary: '#14b1ab',
        gray: '#808080',
        label: '#9d9d9d',
        audioDefault: whiteColor,
        white: whiteColor,
        buttonPrimary: whiteColor,
        buttonMinor: '#999',
        selectedColor: '#EC524B',
        black: blackColor,
    },
    background: {
        primary: '#f4f4f4',
        header: whiteColor,
        card: whiteColor,
        footer: whiteColor,
        audioDefault: 'rgba(48, 48, 48, 0.7)',
        thumb: '#cacaca',
        skeleton: '#eee',
        buttonPrimary: primaryColor,
        buttonMinor: 'transparent',
    },
    paddings: {
        primary: {
            paddingLeft: '20px',
            paddingRight: '20px',
        },
        tablet: {
            paddingLeft: '40px',
            paddingRight: '40px',
        },
    },
    border: {
        buttonPrimary: '1px solid transparent',
        buttonMinor: '1px solid #f1f1f1',
    },
    borderRadius: {
        primary: '5px',
        button: '5px',
    },
    fontSize: {
        primary: '18px',
    },
    fontWeight: {
        buttonPrimary: 700,
        semiBold: 600,
    },
    fontFamily: {
        sans: '"IBM Plex Sans", Arial, sans-serif',
        serif: '"IBM Plex Serif", serif',
        addToHomeScreen: 'Arial, sans-serif',
    },
    width: {
        wrap: '1460px',
        thumb: '3px',
    },
    height: {
        header: {
            primary: '83px',
            tablet: '76px',
            desktop: '112px',
        },
        thumb: '3px',
        readerPagination: '65px',
    },
    transition: {
        fast: '.1s ease-in-out',
        medium: '.2s ease-in-out',
        long: '.3s ease-in-out',
    },
};

export type ITheme = typeof theme;

export default theme;
