import CompilationAliases from '../constants/compilations';

export interface ISelectFieldOption {
    value: string;
    label: string;
}

export enum PreviewTypes {
    EBOOK = 'ebook',
    ABOOK = 'abook',
    MAIN = 'main',
}

export enum TagDisplayNames {
    Popular = 'Популярное',
    New = 'Новинки',
    Recommend = 'Рекомендуем',
    ForParents = 'Для родителей',
}

export interface IDataState {
    isError: boolean;
    isLoading: {
        library: boolean;
        status: boolean;
    },
    footerText: string;
    infoText: string;
    contacts: string;
    isAuth: boolean;
    authors: ISelectFieldOption[];
    genres: string[];
}

export enum AudioStatus {
    PLAY = 'PLAY',
    PAUSE = 'PAUSE',
    STOP = 'STOP',
}

export interface ICurrentAudio {
    name: string | null;
    authorName: string | null;
    coverLink: string | null;
    id: string | null;
    fileSrc: string | undefined;
    time: {
        currentTime: number | undefined,
        totalTime: number | undefined,
        speed: number | undefined,
    };
}
export interface IAudioDataState {
    status: AudioStatus;
    loading: boolean;
    showAudio: boolean;
    showAudioSpeedController: boolean;
    current: ICurrentAudio;
}

export interface ISelectsItemState {
    value: string | CompilationAliases;
    label: string;
}

export interface ISelectsDataState {
    creators: ISelectsItemState[] | [];
    compilation: ISelectsItemState[] | [];
}
