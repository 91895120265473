import React from 'react';
import { Provider } from 'react-redux';
import {
    ApolloClient, InMemoryCache, from, HttpLink,
} from '@apollo/client';
import { ApolloProvider } from '@apollo/client/react';
import { App } from 'components';
import { offsetLimitPagination } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { store } from '../../redux/store';
import ThemeProvider from '../../themes/provider/ThemeProvider';

const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
        graphQLErrors.map(({ message }) => {
            // eslint-disable-next-line no-console
            console.error(`Graphql error ${message}`);
            return null;
        });
    }
});

const link = from([
    errorLink,
    new HttpLink({ uri: '/api/query' }),
]);

const client = new ApolloClient({
    link,
    cache: new InMemoryCache({
        typePolicies: {
            Preview: {
                keyFields: ['id'],
            },
            Category: {
                keyFields: ['id'],
                fields: {
                    contents: offsetLimitPagination(['id', 'sort', 'paramFilters']),
                },
            },
            Tag: {
                keyFields: ['id'],
                fields: {
                    contents: offsetLimitPagination(['id', 'sort', 'paramFilters']),
                },
            },
            Service: {
                keyFields: ['id'],
                fields: {
                    contents: offsetLimitPagination(['id', 'sort', 'paramFilters']),
                    bookLibrary: offsetLimitPagination(),
                },
            },
            Content: {
                keyFields: ['id'],
            },
        },
    }),
});

export const Root = () => (
    <Provider store={store}>
        <ApolloProvider client={client}>
            <ThemeProvider>
                <App />
            </ThemeProvider>
        </ApolloProvider>
    </Provider>
);
