export const firebaseProjectToken = 'ee08190b-952e-4875-876d-afb6824a833c';

// eslint-disable-next-line max-len
export const firebaseVapidKey = 'BCMujmtuLqckVUCgr3WJW4af04rbSGY8NQXjX_-7TrhHpI6fGlXMlb4A86vA0b1Aon19Jn5vLodU7aDuChJhTAo';

export const firebaseConfig = {
    apiKey: 'AIzaSyDDDZqOU8m_mraJYEOQ5MxUWHExRlLr41E',
    authDomain: 'smartbooks-rb-a1.firebaseapp.com',
    projectId: 'smartbooks-rb-a1',
    storageBucket: 'smartbooks-rb-a1.appspot.com',
    messagingSenderId: '11585395808',
    appId: '1:11585395808:web:c509051a270ac614d1f705',
};
