import React from 'react';
import { AudioGlobal } from 'components';
import { useAppSelector } from '../../hooks/redux';

// Interfaces
interface IAudio {
    handlePlay: () => void;
    handlePause: () => void;
}

// Component
export const Audio = (props: IAudio) => {
    const {
        handlePlay,
        handlePause,
    } = props;

    const audioState = useAppSelector((store) => store.audio);
    const { showAudio } = audioState;

    if (showAudio) {
        return (
            <AudioGlobal
                handlePlay={handlePlay}
                handlePause={handlePause}
            />
        );
    }
    return null;
};
