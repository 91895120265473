import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AudioStatus, IAudioDataState, ICurrentAudio } from '../../types';

const initialState: IAudioDataState = {
    status: AudioStatus.STOP,
    loading: false,
    showAudio: false,
    showAudioSpeedController: false,
    current: {
        name: null,
        authorName: null,
        coverLink: null,
        id: null,
        fileSrc: undefined,
        time: {
            currentTime: 0,
            totalTime: 0,
            speed: 1,
        },
    },
};
/* eslint-disable no-param-reassign */
export const audioSlice = createSlice({
    name: 'audio',
    initialState,
    reducers: {
        clearAudio: () => initialState,
        setAudioStatus: (state, action: PayloadAction<AudioStatus>) => {
            state.status = action.payload;
        },
        setAudioCurrent: (state, action: PayloadAction<ICurrentAudio>) => {
            state.current = action.payload;
        },
        setAudioLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setAudioCurrentTime: (state, action: PayloadAction<number>) => {
            state.current.time.currentTime = action.payload;
        },
        setAudioTotalTime: (state, action: PayloadAction<number>) => {
            state.current.time.totalTime = action.payload;
        },
        setAudioSpeed: (state, action: PayloadAction<number>) => {
            state.current.time.speed = action.payload;
        },
        setShowAudio: (state, action: PayloadAction<boolean>) => {
            state.showAudio = action.payload;
        },
        setShowAudioSpeedController: (state, action: PayloadAction<boolean>) => {
            state.showAudioSpeedController = action.payload;
        },
    },
});

export const {
    setAudioStatus,
    setAudioCurrent,
    setAudioLoading,
    setAudioCurrentTime,
    setAudioTotalTime,
    setAudioSpeed,
    setShowAudio,
    setShowAudioSpeedController,
} = audioSlice.actions;

export default audioSlice.reducer;
