import React from 'react';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { AudioSpeedController } from '../AudioSpeedController';
import Breakpoints from '../../themes/constants/breakpoints';
import { useAppSelector } from '../../hooks/redux';
import { RootState } from '../../redux/store';

const StyledAudioSpeedController = styled(AudioSpeedController)<{ open: boolean, isBooksDetailListenPage: boolean }>`
    position: fixed;
    right: ${({ open }) => (open ? '0' : '-100%')};
    bottom: ${({ isBooksDetailListenPage }) => (isBooksDetailListenPage ? '100px' : '50px')};
    border-radius: 10px 0 0 10px;
    @media only screen and (${Breakpoints.TABLET}) {
        bottom: ${({ isBooksDetailListenPage }) => (isBooksDetailListenPage ? '88px' : '50px')};
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        bottom: ${({ open }) => (open ? '50px' : '-100%')};
        right: 0;
        transition: ${({ theme }) => `bottom ${theme.transition.long}`};
        border-radius: 10px;
    }
    @media only screen and (${Breakpoints.LAPTOP_L}) {
        right: calc(50% - 730px);
    }
`;

export const AudioSpeedControllerPortal = () => {
    const portal = document.getElementById('audio-speed-controller-portal');
    const location = useLocation();
    const isLaptop = useMediaQuery({ query: `(${Breakpoints.LAPTOP})` });
    const isBooksDetailListenPage = location.pathname.includes('/listen');
    const audioState = useAppSelector((state: RootState) => state.audio);
    const open = audioState.showAudioSpeedController;
    const { showAudio } = audioState;

    const controller = (
        <StyledAudioSpeedController
            open={!!open}
            isBooksDetailListenPage={isBooksDetailListenPage}
        />
    );

    return portal && (!isLaptop || showAudio)
        ? createPortal(controller, portal) : null;
};
