import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Category = {
  __typename?: 'Category';
  alias: Scalars['String'];
  contents: Array<Content>;
  id: Scalars['ID'];
  localizations?: Maybe<Array<I18nCategory>>;
  name: Scalars['String'];
  params: Array<MetaContentParam>;
  priority: Scalars['Int'];
  tags?: Maybe<Array<Tag>>;
  totalContent: Scalars['Int'];
  totalContents: Scalars['Int'];
  type: Scalars['String'];
};


export type CategoryContentsArgs = {
  filter?: Maybe<ContentFilter>;
  library?: Maybe<LibraryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  paramFilters?: Maybe<ParamFilters>;
  sort?: Maybe<SortByParam>;
  tagID?: Maybe<Scalars['ID']>;
};


export type CategoryLocalizationsArgs = {
  lang: Array<Scalars['String']>;
};


export type CategoryParamsArgs = {
  name: Scalars['String'];
};


export type CategoryTotalContentsArgs = {
  filter?: Maybe<ContentFilter>;
  library?: Maybe<LibraryFilter>;
  paramFilters?: Maybe<ParamFilters>;
  sort?: Maybe<SortByParam>;
  tagID?: Maybe<Scalars['ID']>;
};

export type ComplexParam = {
  __typename?: 'ComplexParam';
  number?: Maybe<Scalars['Int']>;
  numbers?: Maybe<Array<Scalars['Int']>>;
  string?: Maybe<Scalars['String']>;
  strings?: Maybe<Array<Scalars['String']>>;
  type: Scalars['String'];
};

export type Content = {
  __typename?: 'Content';
  categories: Array<Category>;
  category: Category;
  description?: Maybe<Scalars['String']>;
  fileExt: Scalars['String'];
  id: Scalars['ID'];
  isInLibrary: Scalars['Boolean'];
  like: Like;
  link: Scalars['String'];
  listeningPosition: Scalars['Float'];
  localizations?: Maybe<Array<I18nContent>>;
  metaContentParams?: Maybe<Array<MetaContentParam>>;
  myRating?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  previews?: Maybe<Array<Preview>>;
  priority: Scalars['Int'];
  protected: Scalars['Boolean'];
  rating?: Maybe<Scalars['Float']>;
  readingPosition: Scalars['Float'];
  relations: Array<Content>;
  tags?: Maybe<Array<Tag>>;
  totalRelations: Scalars['Int'];
  type: Scalars['String'];
  view: View;
};


export type ContentIsInLibraryArgs = {
  type: LibraryType;
};


export type ContentLocalizationsArgs = {
  lang: Array<Scalars['String']>;
};


export type ContentRelationsArgs = {
  categoryID?: Maybe<Scalars['ID']>;
};


export type ContentTotalRelationsArgs = {
  categoryID?: Maybe<Scalars['ID']>;
};

export type ContentFilter = {
  typeName?: Maybe<Scalars['String']>;
};

export type I18nCategory = {
  __typename?: 'I18nCategory';
  id: Scalars['ID'];
  lang: Scalars['String'];
  name: Scalars['String'];
};

export type I18nContent = {
  __typename?: 'I18nContent';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lang: Scalars['String'];
  link: Scalars['String'];
  name: Scalars['String'];
};

export type I18nMetaContentParam = {
  __typename?: 'I18nMetaContentParam';
  id: Scalars['ID'];
  lang: Scalars['String'];
  value: Scalars['String'];
};

export type I18nTag = {
  __typename?: 'I18nTag';
  displayName: Scalars['String'];
  id: Scalars['ID'];
  lang: Scalars['String'];
};

export type LibraryFilter = {
  logicOperator: LogicOperator;
  negative: Scalars['Boolean'];
  values: Array<LibraryType>;
};

export enum LibraryType {
  Readed = 'READED',
  ReadNow = 'READ_NOW',
  Top = 'TOP'
}

export type Like = {
  __typename?: 'Like';
  id: Scalars['ID'];
  isLiked: Scalars['Boolean'];
  total: Scalars['Int'];
};

export enum LogicOperator {
  And = 'and',
  Or = 'or'
}

export type MetaContentParam = Param & {
  __typename?: 'MetaContentParam';
  complex?: Maybe<ComplexParam>;
  id: Scalars['ID'];
  localizations?: Maybe<Array<I18nMetaContentParam>>;
  name: Scalars['String'];
  value: Scalars['String'];
};


export type MetaContentParamLocalizationsArgs = {
  lang: Array<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addToLibrary?: Maybe<Content>;
  deactivateSubs?: Maybe<Scalars['Boolean']>;
  like: Like;
  listeningPosition?: Maybe<Content>;
  rating?: Maybe<Content>;
  readingPosition?: Maybe<Content>;
  removeFromLibrary?: Maybe<Content>;
  view: View;
};


export type MutationAddToLibraryArgs = {
  id: Scalars['ID'];
  type: LibraryType;
};


export type MutationLikeArgs = {
  id: Scalars['ID'];
};


export type MutationListeningPositionArgs = {
  id: Scalars['ID'];
  position: Scalars['Float'];
};


export type MutationRatingArgs = {
  id: Scalars['ID'];
  rate: Scalars['Int'];
};


export type MutationReadingPositionArgs = {
  id: Scalars['ID'];
  position: Scalars['Float'];
};


export type MutationRemoveFromLibraryArgs = {
  id: Scalars['ID'];
  type: LibraryType;
};


export type MutationViewArgs = {
  id: Scalars['ID'];
};

export enum Order {
  Asc = 'asc',
  Desc = 'desc',
  Rand = 'rand'
}

export type Param = {
  id: Scalars['ID'];
  name: Scalars['String'];
  /** Base64 encoded JSON object */
  value: Scalars['String'];
};

export type ParamFilter = {
  paramName: Scalars['String'];
  value?: Maybe<Array<Scalars['String']>>;
};

export type ParamFilters = {
  logicOperator: LogicOperator;
  negative: Scalars['Boolean'];
  params?: Maybe<Array<Maybe<ParamFilter>>>;
};

export type Point = {
  __typename?: 'Point';
  flowURL?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  service: Service;
  sid: Scalars['String'];
  sskey: Scalars['String'];
  tariff: Scalars['Int'];
  verify?: Maybe<Scalars['Boolean']>;
};

export type Preview = {
  __typename?: 'Preview';
  ext: Scalars['String'];
  id: Scalars['ID'];
  link: Scalars['String'];
  priority: Scalars['Int'];
  size: Scalars['Int'];
  type: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  languages: Array<Scalars['String']>;
  point?: Maybe<Point>;
  user?: Maybe<User>;
};

export enum SearchField {
  Creator = 'creator',
  Description = 'description',
  ExtraShortDescription = 'extraShortDescription',
  Name = 'name',
  ShortDescription = 'shortDescription'
}

export type Service = {
  __typename?: 'Service';
  alias: Scalars['String'];
  bookLibrary: Array<Content>;
  categories?: Maybe<Array<Category>>;
  category?: Maybe<Category>;
  categoryByAlias?: Maybe<Category>;
  content?: Maybe<Content>;
  contents: Array<Content>;
  id: Scalars['ID'];
  languages: Array<Scalars['String']>;
  name: Scalars['String'];
  params: Array<MetaContentParam>;
  search: Array<Content>;
  searchByField: Array<Content>;
  tag?: Maybe<Tag>;
  tagByName?: Maybe<Tag>;
  tags?: Maybe<Array<Tag>>;
  totalContent: Scalars['Int'];
  totalContents: Scalars['Int'];
};


export type ServiceBookLibraryArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortByParam>;
  type: LibraryType;
};


export type ServiceCategoryArgs = {
  id: Scalars['ID'];
};


export type ServiceCategoryByAliasArgs = {
  alias: Scalars['String'];
};


export type ServiceContentArgs = {
  id: Scalars['ID'];
};


export type ServiceContentsArgs = {
  filter?: Maybe<ContentFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  paramFilters?: Maybe<ParamFilters>;
  sort?: Maybe<SortByParam>;
};


export type ServiceParamsArgs = {
  name?: Maybe<Scalars['String']>;
};


export type ServiceSearchArgs = {
  fields?: Array<SearchField>;
  lang?: Array<Scalars['String']>;
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  query: Scalars['String'];
  sort?: Maybe<SortByParam>;
};


export type ServiceSearchByFieldArgs = {
  fields?: Maybe<Array<SearchField>>;
  lang?: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  query: Scalars['String'];
  sort?: Maybe<SortByParam>;
};


export type ServiceTagArgs = {
  id: Scalars['ID'];
};


export type ServiceTagByNameArgs = {
  name: Scalars['String'];
};


export type ServiceTotalContentsArgs = {
  filter?: Maybe<ContentFilter>;
  paramFilters?: Maybe<ParamFilters>;
  sort?: Maybe<SortByParam>;
};

export type SortByParam = {
  order: Order;
  paramName?: Maybe<Scalars['String']>;
};

export type Tag = {
  __typename?: 'Tag';
  contents: Array<Content>;
  description?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  id: Scalars['ID'];
  localizations?: Maybe<Array<I18nTag>>;
  name: Scalars['String'];
  priority: Scalars['Int'];
  totalContent: Scalars['Int'];
  totalContents: Scalars['Int'];
};


export type TagContentsArgs = {
  categoryID?: Maybe<Scalars['ID']>;
  filter?: Maybe<ContentFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  paramFilters?: Maybe<ParamFilters>;
  sort?: Maybe<SortByParam>;
};


export type TagLocalizationsArgs = {
  lang: Array<Scalars['String']>;
};


export type TagTotalContentsArgs = {
  categoryID?: Maybe<Scalars['ID']>;
  filter?: Maybe<ContentFilter>;
  paramFilters?: Maybe<ParamFilters>;
};

export type User = {
  __typename?: 'User';
  msisdn: Scalars['Int'];
  profileId: Scalars['Int'];
  sid: Scalars['String'];
  tariff: Scalars['Int'];
};

export type View = {
  __typename?: 'View';
  id: Scalars['ID'];
  isViewed: Scalars['Boolean'];
  total: Scalars['Int'];
};

export type PointQueryVariables = Exact<{
  name?: Scalars['String'];
}>;


export type PointQuery = (
  { __typename?: 'Query' }
  & { point?: Maybe<(
    { __typename?: 'Point' }
    & Pick<Point, 'id' | 'flowURL' | 'sskey' | 'verify'>
    & { service: (
      { __typename?: 'Service' }
      & Pick<Service, 'id' | 'totalContent'>
      & { categories?: Maybe<Array<(
        { __typename?: 'Category' }
        & CategoryPreFragment
      )>>, params: Array<(
        { __typename?: 'MetaContentParam' }
        & Pick<MetaContentParam, 'id' | 'name' | 'value'>
      )> }
    ) }
  )> }
);

export type VerifyQueryVariables = Exact<{ [key: string]: never; }>;


export type VerifyQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & UserFragment
  )>, point?: Maybe<(
    { __typename?: 'Point' }
    & Pick<Point, 'id' | 'verify' | 'flowURL'>
  )> }
);

export type ParamsQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type ParamsQuery = (
  { __typename?: 'Query' }
  & { point?: Maybe<(
    { __typename?: 'Point' }
    & Pick<Point, 'id'>
    & { service: (
      { __typename?: 'Service' }
      & Pick<Service, 'id'>
      & { params: Array<(
        { __typename?: 'MetaContentParam' }
        & Pick<MetaContentParam, 'id' | 'name' | 'value'>
      )> }
    ) }
  )> }
);

export type ShortContentsQueryVariables = Exact<{
  offset?: Scalars['Int'];
  limit?: Scalars['Int'];
  paramFilters?: Maybe<ParamFilters>;
  filter?: Maybe<ContentFilter>;
  sort?: Maybe<SortByParam>;
}>;


export type ShortContentsQuery = (
  { __typename?: 'Query' }
  & { point?: Maybe<(
    { __typename?: 'Point' }
    & Pick<Point, 'id'>
    & { service: (
      { __typename?: 'Service' }
      & Pick<Service, 'id'>
      & { contents: Array<(
        { __typename?: 'Content' }
        & ShortContentFragment
      )> }
    ) }
  )> }
);

export type ContentByCategoryQueryVariables = Exact<{
  id: Scalars['ID'];
  offset?: Scalars['Int'];
  limit?: Scalars['Int'];
  paramFilters?: Maybe<ParamFilters>;
  filter?: Maybe<ContentFilter>;
  sort?: Maybe<SortByParam>;
}>;


export type ContentByCategoryQuery = (
  { __typename?: 'Query' }
  & { point?: Maybe<(
    { __typename?: 'Point' }
    & Pick<Point, 'id'>
    & { service: (
      { __typename?: 'Service' }
      & Pick<Service, 'id'>
      & { category?: Maybe<(
        { __typename?: 'Category' }
        & CategoryFragment
      )> }
    ) }
  )> }
);

export type ShortContentByCategoryQueryVariables = Exact<{
  id: Scalars['ID'];
  offset?: Scalars['Int'];
  limit?: Scalars['Int'];
  paramFilters?: Maybe<ParamFilters>;
  filter?: Maybe<ContentFilter>;
  sort?: Maybe<SortByParam>;
  library?: Maybe<LibraryFilter>;
}>;


export type ShortContentByCategoryQuery = (
  { __typename?: 'Query' }
  & { point?: Maybe<(
    { __typename?: 'Point' }
    & Pick<Point, 'id'>
    & { service: (
      { __typename?: 'Service' }
      & Pick<Service, 'id'>
      & { category?: Maybe<(
        { __typename?: 'Category' }
        & ShortCategoryFragment
      )> }
    ) }
  )> }
);

export type SearchShortContentQueryVariables = Exact<{
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  query: Scalars['String'];
  fields?: Maybe<Array<SearchField> | SearchField>;
  sort?: Maybe<SortByParam>;
}>;


export type SearchShortContentQuery = (
  { __typename?: 'Query' }
  & { point?: Maybe<(
    { __typename?: 'Point' }
    & Pick<Point, 'id'>
    & { service: (
      { __typename?: 'Service' }
      & Pick<Service, 'id'>
      & { searchByField: Array<(
        { __typename?: 'Content' }
        & SearchContentFragment
      )> }
    ) }
  )> }
);

export type SearchContentQueryVariables = Exact<{
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  query: Scalars['String'];
  fields?: Maybe<Array<SearchField> | SearchField>;
  sort?: Maybe<SortByParam>;
}>;


export type SearchContentQuery = (
  { __typename?: 'Query' }
  & { point?: Maybe<(
    { __typename?: 'Point' }
    & Pick<Point, 'id'>
    & { service: (
      { __typename?: 'Service' }
      & Pick<Service, 'id'>
      & { searchByField: Array<(
        { __typename?: 'Content' }
        & ContentFragment
      )> }
    ) }
  )> }
);

export type ContentByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ContentByIdQuery = (
  { __typename?: 'Query' }
  & { point?: Maybe<(
    { __typename?: 'Point' }
    & Pick<Point, 'id'>
    & { service: (
      { __typename?: 'Service' }
      & Pick<Service, 'id'>
      & { content?: Maybe<(
        { __typename?: 'Content' }
        & ContentFragment
      )> }
    ) }
  )> }
);

export type LibraryContentByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LibraryContentByIdQuery = (
  { __typename?: 'Query' }
  & { point?: Maybe<(
    { __typename?: 'Point' }
    & Pick<Point, 'id'>
    & { service: (
      { __typename?: 'Service' }
      & Pick<Service, 'id'>
      & { content?: Maybe<(
        { __typename?: 'Content' }
        & LibraryContentFragment
      )> }
    ) }
  )> }
);

export type TagsQueryVariables = Exact<{ [key: string]: never; }>;


export type TagsQuery = (
  { __typename?: 'Query' }
  & { point?: Maybe<(
    { __typename?: 'Point' }
    & Pick<Point, 'id'>
    & { service: (
      { __typename?: 'Service' }
      & Pick<Service, 'id'>
      & { tags?: Maybe<Array<(
        { __typename?: 'Tag' }
        & TagFragment
      )>> }
    ) }
  )> }
);

export type LibraryContentByTypeQueryVariables = Exact<{
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  type: LibraryType;
  sort?: Maybe<SortByParam>;
}>;


export type LibraryContentByTypeQuery = (
  { __typename?: 'Query' }
  & { point?: Maybe<(
    { __typename?: 'Point' }
    & Pick<Point, 'id'>
    & { service: (
      { __typename?: 'Service' }
      & Pick<Service, 'id'>
      & { bookLibrary: Array<(
        { __typename?: 'Content' }
        & ShortContentFragment
      )> }
    ) }
  )> }
);

export type CategoryPreFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'alias' | 'name' | 'totalContent'>
);

export type CategoryFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'alias' | 'name'>
  & { contents: Array<(
    { __typename?: 'Content' }
    & ContentFragment
  )> }
);

export type ShortCategoryFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'alias' | 'name'>
  & { contents: Array<(
    { __typename?: 'Content' }
    & ShortContentFragment
  )> }
);

export type TagFragment = (
  { __typename?: 'Tag' }
  & Pick<Tag, 'id' | 'name' | 'displayName'>
);

export type UserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'msisdn' | 'tariff' | 'sid' | 'profileId'>
);

export type LibraryContentFragment = (
  { __typename?: 'Content' }
  & Pick<Content, 'id' | 'readingPosition' | 'listeningPosition'>
  & { top: Content['isInLibrary'], readNow: Content['isInLibrary'], readed: Content['isInLibrary'] }
);

export type SearchContentFragment = (
  { __typename?: 'Content' }
  & Pick<Content, 'id' | 'name' | 'type'>
  & { metaContentParams?: Maybe<Array<(
    { __typename?: 'MetaContentParam' }
    & MetaContentParamFragment
  )>> }
);

export type ContentFragment = (
  { __typename?: 'Content' }
  & Pick<Content, 'id' | 'description' | 'link' | 'name' | 'type' | 'readingPosition' | 'listeningPosition'>
  & { top: Content['isInLibrary'], readNow: Content['isInLibrary'], readed: Content['isInLibrary'] }
  & { category: (
    { __typename?: 'Category' }
    & Pick<Category, 'alias' | 'id'>
  ), metaContentParams?: Maybe<Array<(
    { __typename?: 'MetaContentParam' }
    & MetaContentParamFragment
  )>>, previews?: Maybe<Array<(
    { __typename?: 'Preview' }
    & PreviewFragment
  )>>, tags?: Maybe<Array<(
    { __typename?: 'Tag' }
    & TagFragment
  )>> }
);

export type ShortContentFragment = (
  { __typename?: 'Content' }
  & Pick<Content, 'id' | 'name' | 'link'>
  & { top: Content['isInLibrary'], readed: Content['isInLibrary'] }
  & { previews?: Maybe<Array<(
    { __typename?: 'Preview' }
    & PreviewFragment
  )>>, metaContentParams?: Maybe<Array<(
    { __typename?: 'MetaContentParam' }
    & MetaContentParamFragment
  )>> }
);

export type MetaContentParamFragment = (
  { __typename?: 'MetaContentParam' }
  & Pick<MetaContentParam, 'id' | 'name' | 'value'>
);

export type PreviewFragment = (
  { __typename?: 'Preview' }
  & Pick<Preview, 'id' | 'link' | 'type'>
);

export type LocalizationFragment = (
  { __typename?: 'I18nTag' }
  & Pick<I18nTag, 'id' | 'displayName'>
);

export type SetReadingPositionMutationVariables = Exact<{
  id: Scalars['ID'];
  position: Scalars['Float'];
}>;


export type SetReadingPositionMutation = (
  { __typename?: 'Mutation' }
  & { readingPosition?: Maybe<(
    { __typename?: 'Content' }
    & Pick<Content, 'id' | 'readingPosition'>
  )> }
);

export type SetListeningPositionMutationVariables = Exact<{
  id: Scalars['ID'];
  position: Scalars['Float'];
}>;


export type SetListeningPositionMutation = (
  { __typename?: 'Mutation' }
  & { listeningPosition?: Maybe<(
    { __typename?: 'Content' }
    & Pick<Content, 'id' | 'listeningPosition'>
  )> }
);

export type AddLibraryTypeMutationVariables = Exact<{
  id: Scalars['ID'];
  type: LibraryType;
}>;


export type AddLibraryTypeMutation = (
  { __typename?: 'Mutation' }
  & { addToLibrary?: Maybe<(
    { __typename?: 'Content' }
    & Pick<Content, 'id' | 'isInLibrary'>
  )> }
);

export type RemoveLibraryTypeMutationVariables = Exact<{
  id: Scalars['ID'];
  type: LibraryType;
}>;


export type RemoveLibraryTypeMutation = (
  { __typename?: 'Mutation' }
  & { removeFromLibrary?: Maybe<(
    { __typename?: 'Content' }
    & Pick<Content, 'id' | 'isInLibrary'>
  )> }
);

export const CategoryPreFragmentDoc = gql`
    fragment CategoryPre on Category {
  id
  alias
  name
  totalContent
}
    `;
export const MetaContentParamFragmentDoc = gql`
    fragment MetaContentParam on MetaContentParam {
  id
  name
  value
}
    `;
export const PreviewFragmentDoc = gql`
    fragment Preview on Preview {
  id
  link
  type
}
    `;
export const TagFragmentDoc = gql`
    fragment Tag on Tag {
  id
  name
  displayName
}
    `;
export const ContentFragmentDoc = gql`
    fragment Content on Content {
  id
  description
  link
  name
  type
  top: isInLibrary(type: TOP)
  readNow: isInLibrary(type: READ_NOW)
  readed: isInLibrary(type: READED)
  readingPosition
  listeningPosition
  category {
    alias
    id
  }
  metaContentParams {
    ...MetaContentParam
  }
  previews {
    ...Preview
  }
  tags {
    ...Tag
  }
}
    ${MetaContentParamFragmentDoc}
${PreviewFragmentDoc}
${TagFragmentDoc}`;
export const CategoryFragmentDoc = gql`
    fragment Category on Category {
  id
  alias
  name
  contents(
    limit: $limit
    offset: $offset
    paramFilters: $paramFilters
    filter: $filter
    sort: $sort
  ) {
    ...Content
  }
}
    ${ContentFragmentDoc}`;
export const ShortContentFragmentDoc = gql`
    fragment ShortContent on Content {
  id
  name
  top: isInLibrary(type: TOP)
  readed: isInLibrary(type: READED)
  link
  previews {
    ...Preview
  }
  metaContentParams {
    ...MetaContentParam
  }
}
    ${PreviewFragmentDoc}
${MetaContentParamFragmentDoc}`;
export const ShortCategoryFragmentDoc = gql`
    fragment ShortCategory on Category {
  id
  alias
  name
  contents(
    limit: $limit
    offset: $offset
    paramFilters: $paramFilters
    filter: $filter
    sort: $sort
    library: $library
  ) {
    ...ShortContent
  }
}
    ${ShortContentFragmentDoc}`;
export const UserFragmentDoc = gql`
    fragment User on User {
  msisdn
  tariff
  sid
  profileId
}
    `;
export const LibraryContentFragmentDoc = gql`
    fragment LibraryContent on Content {
  id
  top: isInLibrary(type: TOP)
  readNow: isInLibrary(type: READ_NOW)
  readed: isInLibrary(type: READED)
  readingPosition
  listeningPosition
}
    `;
export const SearchContentFragmentDoc = gql`
    fragment SearchContent on Content {
  id
  name
  type
  metaContentParams {
    ...MetaContentParam
  }
}
    ${MetaContentParamFragmentDoc}`;
export const LocalizationFragmentDoc = gql`
    fragment Localization on I18nTag {
  id
  displayName
}
    `;
export const PointDocument = gql`
    query Point($name: String! = "genres") {
  point {
    id
    flowURL
    sskey
    verify
    service {
      id
      categories {
        ...CategoryPre
      }
      totalContent
      params(name: $name) {
        id
        name
        value
      }
    }
  }
}
    ${CategoryPreFragmentDoc}`;

/**
 * __usePointQuery__
 *
 * To run a query within a React component, call `usePointQuery` and pass it any options that fit your needs.
 * When your component renders, `usePointQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePointQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function usePointQuery(baseOptions?: Apollo.QueryHookOptions<PointQuery, PointQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PointQuery, PointQueryVariables>(PointDocument, options);
      }
export function usePointLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PointQuery, PointQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PointQuery, PointQueryVariables>(PointDocument, options);
        }
export type PointQueryHookResult = ReturnType<typeof usePointQuery>;
export type PointLazyQueryHookResult = ReturnType<typeof usePointLazyQuery>;
export type PointQueryResult = Apollo.QueryResult<PointQuery, PointQueryVariables>;
export const VerifyDocument = gql`
    query Verify {
  user {
    ...User
  }
  point {
    id
    verify
    flowURL
  }
}
    ${UserFragmentDoc}`;

/**
 * __useVerifyQuery__
 *
 * To run a query within a React component, call `useVerifyQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyQuery({
 *   variables: {
 *   },
 * });
 */
export function useVerifyQuery(baseOptions?: Apollo.QueryHookOptions<VerifyQuery, VerifyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifyQuery, VerifyQueryVariables>(VerifyDocument, options);
      }
export function useVerifyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyQuery, VerifyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifyQuery, VerifyQueryVariables>(VerifyDocument, options);
        }
export type VerifyQueryHookResult = ReturnType<typeof useVerifyQuery>;
export type VerifyLazyQueryHookResult = ReturnType<typeof useVerifyLazyQuery>;
export type VerifyQueryResult = Apollo.QueryResult<VerifyQuery, VerifyQueryVariables>;
export const ParamsDocument = gql`
    query Params($name: String!) {
  point {
    id
    service {
      id
      params(name: $name) {
        id
        name
        value
      }
    }
  }
}
    `;

/**
 * __useParamsQuery__
 *
 * To run a query within a React component, call `useParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParamsQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useParamsQuery(baseOptions: Apollo.QueryHookOptions<ParamsQuery, ParamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ParamsQuery, ParamsQueryVariables>(ParamsDocument, options);
      }
export function useParamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ParamsQuery, ParamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ParamsQuery, ParamsQueryVariables>(ParamsDocument, options);
        }
export type ParamsQueryHookResult = ReturnType<typeof useParamsQuery>;
export type ParamsLazyQueryHookResult = ReturnType<typeof useParamsLazyQuery>;
export type ParamsQueryResult = Apollo.QueryResult<ParamsQuery, ParamsQueryVariables>;
export const ShortContentsDocument = gql`
    query ShortContents($offset: Int! = 0, $limit: Int! = 10, $paramFilters: ParamFilters, $filter: ContentFilter, $sort: SortByParam) {
  point {
    id
    service {
      id
      contents(
        offset: $offset
        limit: $limit
        paramFilters: $paramFilters
        filter: $filter
        sort: $sort
      ) {
        ...ShortContent
      }
    }
  }
}
    ${ShortContentFragmentDoc}`;

/**
 * __useShortContentsQuery__
 *
 * To run a query within a React component, call `useShortContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShortContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShortContentsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      paramFilters: // value for 'paramFilters'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useShortContentsQuery(baseOptions?: Apollo.QueryHookOptions<ShortContentsQuery, ShortContentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShortContentsQuery, ShortContentsQueryVariables>(ShortContentsDocument, options);
      }
export function useShortContentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShortContentsQuery, ShortContentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShortContentsQuery, ShortContentsQueryVariables>(ShortContentsDocument, options);
        }
export type ShortContentsQueryHookResult = ReturnType<typeof useShortContentsQuery>;
export type ShortContentsLazyQueryHookResult = ReturnType<typeof useShortContentsLazyQuery>;
export type ShortContentsQueryResult = Apollo.QueryResult<ShortContentsQuery, ShortContentsQueryVariables>;
export const ContentByCategoryDocument = gql`
    query ContentByCategory($id: ID!, $offset: Int! = 0, $limit: Int! = 10, $paramFilters: ParamFilters, $filter: ContentFilter, $sort: SortByParam) {
  point {
    id
    service {
      id
      category(id: $id) {
        ...Category
      }
    }
  }
}
    ${CategoryFragmentDoc}`;

/**
 * __useContentByCategoryQuery__
 *
 * To run a query within a React component, call `useContentByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentByCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      paramFilters: // value for 'paramFilters'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useContentByCategoryQuery(baseOptions: Apollo.QueryHookOptions<ContentByCategoryQuery, ContentByCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContentByCategoryQuery, ContentByCategoryQueryVariables>(ContentByCategoryDocument, options);
      }
export function useContentByCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContentByCategoryQuery, ContentByCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContentByCategoryQuery, ContentByCategoryQueryVariables>(ContentByCategoryDocument, options);
        }
export type ContentByCategoryQueryHookResult = ReturnType<typeof useContentByCategoryQuery>;
export type ContentByCategoryLazyQueryHookResult = ReturnType<typeof useContentByCategoryLazyQuery>;
export type ContentByCategoryQueryResult = Apollo.QueryResult<ContentByCategoryQuery, ContentByCategoryQueryVariables>;
export const ShortContentByCategoryDocument = gql`
    query ShortContentByCategory($id: ID!, $offset: Int! = 0, $limit: Int! = 10, $paramFilters: ParamFilters, $filter: ContentFilter, $sort: SortByParam, $library: LibraryFilter) {
  point {
    id
    service {
      id
      category(id: $id) {
        ...ShortCategory
      }
    }
  }
}
    ${ShortCategoryFragmentDoc}`;

/**
 * __useShortContentByCategoryQuery__
 *
 * To run a query within a React component, call `useShortContentByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useShortContentByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShortContentByCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      paramFilters: // value for 'paramFilters'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      library: // value for 'library'
 *   },
 * });
 */
export function useShortContentByCategoryQuery(baseOptions: Apollo.QueryHookOptions<ShortContentByCategoryQuery, ShortContentByCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShortContentByCategoryQuery, ShortContentByCategoryQueryVariables>(ShortContentByCategoryDocument, options);
      }
export function useShortContentByCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShortContentByCategoryQuery, ShortContentByCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShortContentByCategoryQuery, ShortContentByCategoryQueryVariables>(ShortContentByCategoryDocument, options);
        }
export type ShortContentByCategoryQueryHookResult = ReturnType<typeof useShortContentByCategoryQuery>;
export type ShortContentByCategoryLazyQueryHookResult = ReturnType<typeof useShortContentByCategoryLazyQuery>;
export type ShortContentByCategoryQueryResult = Apollo.QueryResult<ShortContentByCategoryQuery, ShortContentByCategoryQueryVariables>;
export const SearchShortContentDocument = gql`
    query SearchShortContent($limit: Int! = 10, $offset: Int! = 0, $query: String!, $fields: [SearchField!], $sort: SortByParam) {
  point {
    id
    service {
      id
      searchByField(
        limit: $limit
        offset: $offset
        query: $query
        fields: $fields
        sort: $sort
      ) {
        ...SearchContent
      }
    }
  }
}
    ${SearchContentFragmentDoc}`;

/**
 * __useSearchShortContentQuery__
 *
 * To run a query within a React component, call `useSearchShortContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchShortContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchShortContentQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      query: // value for 'query'
 *      fields: // value for 'fields'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useSearchShortContentQuery(baseOptions: Apollo.QueryHookOptions<SearchShortContentQuery, SearchShortContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchShortContentQuery, SearchShortContentQueryVariables>(SearchShortContentDocument, options);
      }
export function useSearchShortContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchShortContentQuery, SearchShortContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchShortContentQuery, SearchShortContentQueryVariables>(SearchShortContentDocument, options);
        }
export type SearchShortContentQueryHookResult = ReturnType<typeof useSearchShortContentQuery>;
export type SearchShortContentLazyQueryHookResult = ReturnType<typeof useSearchShortContentLazyQuery>;
export type SearchShortContentQueryResult = Apollo.QueryResult<SearchShortContentQuery, SearchShortContentQueryVariables>;
export const SearchContentDocument = gql`
    query SearchContent($limit: Int! = 10, $offset: Int! = 0, $query: String!, $fields: [SearchField!], $sort: SortByParam) {
  point {
    id
    service {
      id
      searchByField(
        limit: $limit
        offset: $offset
        query: $query
        fields: $fields
        sort: $sort
      ) {
        ...Content
      }
    }
  }
}
    ${ContentFragmentDoc}`;

/**
 * __useSearchContentQuery__
 *
 * To run a query within a React component, call `useSearchContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchContentQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      query: // value for 'query'
 *      fields: // value for 'fields'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useSearchContentQuery(baseOptions: Apollo.QueryHookOptions<SearchContentQuery, SearchContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchContentQuery, SearchContentQueryVariables>(SearchContentDocument, options);
      }
export function useSearchContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchContentQuery, SearchContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchContentQuery, SearchContentQueryVariables>(SearchContentDocument, options);
        }
export type SearchContentQueryHookResult = ReturnType<typeof useSearchContentQuery>;
export type SearchContentLazyQueryHookResult = ReturnType<typeof useSearchContentLazyQuery>;
export type SearchContentQueryResult = Apollo.QueryResult<SearchContentQuery, SearchContentQueryVariables>;
export const ContentByIdDocument = gql`
    query ContentById($id: ID!) {
  point {
    id
    service {
      id
      content(id: $id) {
        ...Content
      }
    }
  }
}
    ${ContentFragmentDoc}`;

/**
 * __useContentByIdQuery__
 *
 * To run a query within a React component, call `useContentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContentByIdQuery(baseOptions: Apollo.QueryHookOptions<ContentByIdQuery, ContentByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContentByIdQuery, ContentByIdQueryVariables>(ContentByIdDocument, options);
      }
export function useContentByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContentByIdQuery, ContentByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContentByIdQuery, ContentByIdQueryVariables>(ContentByIdDocument, options);
        }
export type ContentByIdQueryHookResult = ReturnType<typeof useContentByIdQuery>;
export type ContentByIdLazyQueryHookResult = ReturnType<typeof useContentByIdLazyQuery>;
export type ContentByIdQueryResult = Apollo.QueryResult<ContentByIdQuery, ContentByIdQueryVariables>;
export const LibraryContentByIdDocument = gql`
    query LibraryContentById($id: ID!) {
  point {
    id
    service {
      id
      content(id: $id) {
        ...LibraryContent
      }
    }
  }
}
    ${LibraryContentFragmentDoc}`;

/**
 * __useLibraryContentByIdQuery__
 *
 * To run a query within a React component, call `useLibraryContentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLibraryContentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLibraryContentByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLibraryContentByIdQuery(baseOptions: Apollo.QueryHookOptions<LibraryContentByIdQuery, LibraryContentByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LibraryContentByIdQuery, LibraryContentByIdQueryVariables>(LibraryContentByIdDocument, options);
      }
export function useLibraryContentByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LibraryContentByIdQuery, LibraryContentByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LibraryContentByIdQuery, LibraryContentByIdQueryVariables>(LibraryContentByIdDocument, options);
        }
export type LibraryContentByIdQueryHookResult = ReturnType<typeof useLibraryContentByIdQuery>;
export type LibraryContentByIdLazyQueryHookResult = ReturnType<typeof useLibraryContentByIdLazyQuery>;
export type LibraryContentByIdQueryResult = Apollo.QueryResult<LibraryContentByIdQuery, LibraryContentByIdQueryVariables>;
export const TagsDocument = gql`
    query Tags {
  point {
    id
    service {
      id
      tags {
        ...Tag
      }
    }
  }
}
    ${TagFragmentDoc}`;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTagsQuery(baseOptions?: Apollo.QueryHookOptions<TagsQuery, TagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
      }
export function useTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagsQuery, TagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
        }
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsQueryResult = Apollo.QueryResult<TagsQuery, TagsQueryVariables>;
export const LibraryContentByTypeDocument = gql`
    query LibraryContentByType($limit: Int! = 10, $offset: Int! = 0, $type: LibraryType!, $sort: SortByParam) {
  point {
    id
    service {
      id
      bookLibrary(limit: $limit, offset: $offset, type: $type, sort: $sort) {
        ...ShortContent
      }
    }
  }
}
    ${ShortContentFragmentDoc}`;

/**
 * __useLibraryContentByTypeQuery__
 *
 * To run a query within a React component, call `useLibraryContentByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useLibraryContentByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLibraryContentByTypeQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      type: // value for 'type'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useLibraryContentByTypeQuery(baseOptions: Apollo.QueryHookOptions<LibraryContentByTypeQuery, LibraryContentByTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LibraryContentByTypeQuery, LibraryContentByTypeQueryVariables>(LibraryContentByTypeDocument, options);
      }
export function useLibraryContentByTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LibraryContentByTypeQuery, LibraryContentByTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LibraryContentByTypeQuery, LibraryContentByTypeQueryVariables>(LibraryContentByTypeDocument, options);
        }
export type LibraryContentByTypeQueryHookResult = ReturnType<typeof useLibraryContentByTypeQuery>;
export type LibraryContentByTypeLazyQueryHookResult = ReturnType<typeof useLibraryContentByTypeLazyQuery>;
export type LibraryContentByTypeQueryResult = Apollo.QueryResult<LibraryContentByTypeQuery, LibraryContentByTypeQueryVariables>;
export const SetReadingPositionDocument = gql`
    mutation SetReadingPosition($id: ID!, $position: Float!) {
  readingPosition(id: $id, position: $position) {
    id
    readingPosition
  }
}
    `;
export type SetReadingPositionMutationFn = Apollo.MutationFunction<SetReadingPositionMutation, SetReadingPositionMutationVariables>;

/**
 * __useSetReadingPositionMutation__
 *
 * To run a mutation, you first call `useSetReadingPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetReadingPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setReadingPositionMutation, { data, loading, error }] = useSetReadingPositionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      position: // value for 'position'
 *   },
 * });
 */
export function useSetReadingPositionMutation(baseOptions?: Apollo.MutationHookOptions<SetReadingPositionMutation, SetReadingPositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetReadingPositionMutation, SetReadingPositionMutationVariables>(SetReadingPositionDocument, options);
      }
export type SetReadingPositionMutationHookResult = ReturnType<typeof useSetReadingPositionMutation>;
export type SetReadingPositionMutationResult = Apollo.MutationResult<SetReadingPositionMutation>;
export type SetReadingPositionMutationOptions = Apollo.BaseMutationOptions<SetReadingPositionMutation, SetReadingPositionMutationVariables>;
export const SetListeningPositionDocument = gql`
    mutation SetListeningPosition($id: ID!, $position: Float!) {
  listeningPosition(id: $id, position: $position) {
    id
    listeningPosition
  }
}
    `;
export type SetListeningPositionMutationFn = Apollo.MutationFunction<SetListeningPositionMutation, SetListeningPositionMutationVariables>;

/**
 * __useSetListeningPositionMutation__
 *
 * To run a mutation, you first call `useSetListeningPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetListeningPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setListeningPositionMutation, { data, loading, error }] = useSetListeningPositionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      position: // value for 'position'
 *   },
 * });
 */
export function useSetListeningPositionMutation(baseOptions?: Apollo.MutationHookOptions<SetListeningPositionMutation, SetListeningPositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetListeningPositionMutation, SetListeningPositionMutationVariables>(SetListeningPositionDocument, options);
      }
export type SetListeningPositionMutationHookResult = ReturnType<typeof useSetListeningPositionMutation>;
export type SetListeningPositionMutationResult = Apollo.MutationResult<SetListeningPositionMutation>;
export type SetListeningPositionMutationOptions = Apollo.BaseMutationOptions<SetListeningPositionMutation, SetListeningPositionMutationVariables>;
export const AddLibraryTypeDocument = gql`
    mutation AddLibraryType($id: ID!, $type: LibraryType!) {
  addToLibrary(id: $id, type: $type) {
    id
    isInLibrary(type: $type)
  }
}
    `;
export type AddLibraryTypeMutationFn = Apollo.MutationFunction<AddLibraryTypeMutation, AddLibraryTypeMutationVariables>;

/**
 * __useAddLibraryTypeMutation__
 *
 * To run a mutation, you first call `useAddLibraryTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLibraryTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLibraryTypeMutation, { data, loading, error }] = useAddLibraryTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAddLibraryTypeMutation(baseOptions?: Apollo.MutationHookOptions<AddLibraryTypeMutation, AddLibraryTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddLibraryTypeMutation, AddLibraryTypeMutationVariables>(AddLibraryTypeDocument, options);
      }
export type AddLibraryTypeMutationHookResult = ReturnType<typeof useAddLibraryTypeMutation>;
export type AddLibraryTypeMutationResult = Apollo.MutationResult<AddLibraryTypeMutation>;
export type AddLibraryTypeMutationOptions = Apollo.BaseMutationOptions<AddLibraryTypeMutation, AddLibraryTypeMutationVariables>;
export const RemoveLibraryTypeDocument = gql`
    mutation RemoveLibraryType($id: ID!, $type: LibraryType!) {
  removeFromLibrary(id: $id, type: $type) {
    id
    isInLibrary(type: $type)
  }
}
    `;
export type RemoveLibraryTypeMutationFn = Apollo.MutationFunction<RemoveLibraryTypeMutation, RemoveLibraryTypeMutationVariables>;

/**
 * __useRemoveLibraryTypeMutation__
 *
 * To run a mutation, you first call `useRemoveLibraryTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLibraryTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLibraryTypeMutation, { data, loading, error }] = useRemoveLibraryTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useRemoveLibraryTypeMutation(baseOptions?: Apollo.MutationHookOptions<RemoveLibraryTypeMutation, RemoveLibraryTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveLibraryTypeMutation, RemoveLibraryTypeMutationVariables>(RemoveLibraryTypeDocument, options);
      }
export type RemoveLibraryTypeMutationHookResult = ReturnType<typeof useRemoveLibraryTypeMutation>;
export type RemoveLibraryTypeMutationResult = Apollo.MutationResult<RemoveLibraryTypeMutation>;
export type RemoveLibraryTypeMutationOptions = Apollo.BaseMutationOptions<RemoveLibraryTypeMutation, RemoveLibraryTypeMutationVariables>;