import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { SpeedValueButton } from './SpeedValueButton';
import { IAudioSpeedValue } from '../../constants/speedValues';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { setShowAudioSpeedController } from '../../redux/features/audio/audioSlice';
import { RootState } from '../../redux/store';

const Wrapper = styled.div`
    width: 120px;
    height: 290px;
    background-color: rgba(48, 48, 48, 0.7);
    z-index: 1;
    border-radius: 10px;
    color: #fff;
    padding: 17px 0 15px;
    backdrop-filter: blur(2px);
    transition: ${({ theme }) => `right ${theme.transition.long}`};
`;

const Title = styled.p`
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    margin: 0 0 10px;
`;

const List = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

interface IAudioSpeedController {
    className?: string
}

export const AudioSpeedController = ({ className }: IAudioSpeedController) => {
    const dispatch = useAppDispatch();
    const audioSpeedValues: IAudioSpeedValue[] = [
        {
            value: 0.25,
            label: 0.25,
        },
        {
            value: 0.5,
            label: 0.5,
        },
        {
            value: 0.75,
            label: 0.75,
        },
        {
            value: 1,
            label: 'Обычная',
        },
        {
            value: 1.25,
            label: 1.25,
        },
        {
            value: 1.5,
            label: 1.5,
        },
        {
            value: 1.75,
            label: 1.75,
        },
    ];
    const audioSpeedControllerRef = useRef<HTMLDivElement>(null);

    const audioState = useAppSelector((state: RootState) => state.audio);
    const open = audioState.showAudioSpeedController;
    const audioSpeed = audioState.current.time.speed;

    const toggleOpen = useCallback(() => {
        dispatch(setShowAudioSpeedController(!open));
    }, [open]);

    const renderSpeedValues = (values: IAudioSpeedValue[]) => values
        .map(({ value, label }) => (
            <SpeedValueButton
                key={value}
                value={value}
                label={label}
                active={audioSpeed === value}
                setOpen={toggleOpen}
            />
        ));

    useEffect(() => {
        const listener = (event: MouseEvent) => {
            if (audioSpeedControllerRef.current) {
                if (!audioSpeedControllerRef.current.contains(event.target as Node) && open) {
                    dispatch(setShowAudioSpeedController(false));
                }
            }
        };
        window.addEventListener('click', listener);
        return () => window.removeEventListener('click', listener);
    }, [open, audioSpeedControllerRef]);

    return (
        <Wrapper
            ref={audioSpeedControllerRef}
            className={className}
        >
            <Title>
                Скорость
                <br />
                воспроизведения
            </Title>
            <List>
                {renderSpeedValues(audioSpeedValues)}
            </List>
        </Wrapper>
    );
};

AudioSpeedController.defaultProps = {
    className: '',
};
